<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Entry Test Result Analysis
      </h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDialogResultAnalysis"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm test result analysis</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in entry_test_result_analysis">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ item.title }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="editResultAnalysis(i, item.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Sửa</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'GeneralFeedback',
                                  params: { id: item.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-success btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-clipboard-list</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Tổng quan</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'DetailFeedback',
                                  params: { id: item.id },
                                }"
                                target="_blank"
                              >
                                <button
                                  class="btn btn-icon btn-light-primary btn-sm ml-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-eye-settings</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Chi tiết</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteResultAnalysis(item.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Xóa</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!entry_test_result_analysis.length">
                    <tr>
                      <td style="text-align: center" colspan="6">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogResultAnalysis"
        max-width="800px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline" v-if="type_form_result_analysis === 'add'"
                >Add test result analysis</span
              >
              <span class="headline" v-else>Edit test result analysis</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogResultAnalysis = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-text-field
                    label="Title"
                    v-model="title_input"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_result_analysis === 'add'"
              @click="btnAddOrEditResultAnalysis(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_result_analysis === 'update'"
              @click="btnAddOrEditResultAnalysis(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogResultAnalysis = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "ResultAnalysis",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      entry_test_result_analysis: [],
      test_result_analysis_id: null,
      is_call_api: false,
      dialogResultAnalysis: false,
      type_form_result_analysis: null,
      title_input: "",
    };
  },
  created() {
    this.getAllResultAnalysis();
  },
  methods: {
    async getAllResultAnalysis() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/test-result-analysis/limit");
        if (res.status === 200) {
          vm.entry_test_result_analysis = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
      }
    },
    openDialogResultAnalysis() {
      this.type_form_result_analysis = "add";
      this.title_input = "";
      this.dialogResultAnalysis = true;
    },
    editResultAnalysis(index, test_result_analysis_id) {
      let data = this.entry_test_result_analysis[index];
      this.title_input = data.title;
      this.test_result_analysis_id = test_result_analysis_id;
      this.type_form_result_analysis = "update";
      this.dialogResultAnalysis = true;
    },
    btnAddOrEditResultAnalysis(type) {
      let vm = this;
      let data = {
        title: this.title_input,
      };
      if (type === 1) {
        vm.is_call_api = true;
        ApiService.post("prep-app/test-result-analysis", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.getAllResultAnalysis();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.is_call_api = false;
              vm.dialogResultAnalysis = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        vm.is_call_api = true;
        ApiService.put(
          "prep-app/test-result-analysis/" + this.test_result_analysis_id,
          data
        )
          .then(function (res) {
            if (res.status === 202) {
              vm.getAllResultAnalysis();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.is_call_api = false;
              vm.dialogResultAnalysis = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    deleteResultAnalysis(result_analysis_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete(
            "prep-app/test-result-analysis/" + result_analysis_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.getAllResultAnalysis();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
